@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Instrument-sans';
  src: url('../common//fonts/instrument-sans-ttf/InstrumentSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Instrument-sans';
  src: url('../common//fonts/instrument-sans-ttf/InstrumentSans-Medium.ttf') format('truetype');
  font-weight: medium;
  font-style: normal;
}

@font-face {
  font-family: 'Darker-grotesque';
  src: url('../common//fonts/darker-grotesque/DarkerGrotesque-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Darker-grotesque';
  src: url('../common//fonts/darker-grotesque/DarkerGrotesque-Medium.ttf') format('truetype');
  font-weight: medium;
  font-style: normal;
}

@font-face {
  font-family: 'Darker-grotesque';
  src: url('../common//fonts/darker-grotesque/DarkerGrotesque-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Darker-grotesque';
  src: url('../common//fonts/darker-grotesque/DarkerGrotesque-ExtraBold.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Figtree';
  src: url('../common//fonts/figtree/Figtree-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Figtree';
  src: url('../common//fonts/figtree/Figtree-Medium.ttf') format('truetype');
  font-weight: medium;
  font-style: normal;
}

body, html {
  width: 100%;
  height: 100%;
  background: white;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  appearance: none;
}

.home {
  background-image: url("/public/assets/hero-bg.jpg");
  width: 100%;
  min-height: 100dvh;
  background-size: cover;
  background-repeat: no-repeat;
}

.about-cont-4 {
  background-image: url("/public/assets/about-last.svg");
  width: 100%;
  height: 490px;
  background-size: cover;
  background-repeat: no-repeat;
}

.team-extra{
  background-image: url("/public/assets/team-bg.svg");
  width: 100%;
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
}


.carousel {
  width: 100%;
  height: 100%;
}

.flickity-container {
  height: 350px;
  margin: auto;
  margin-right: 0%;
  margin-top: 4.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

}

.carousel.flickity-viewport {
  height: 350px;
  width: 100%;  
  /* margin: auto; */
  /* background: pink; */
}
.carousel-cell {
  width: 55%;
  height: 100%;
  margin-left: 15px;
}

.carousel-cell > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transform: scale(0.84);
  transition: transform 0.3s ease;
}

.carousel-cell.is-selected > img {
  /* background: green; */
  transform: scale(1.085);
}

.carousel-cell.is-selected > .yooy {
  padding-left: 75px;
}

.carousel-cell:last-of-type.is-selected {
  margin-right: 55px;
}
/* Hide Flickity's page dots */

@layer utilities {
  /* -- SPACING UTILITY -- */
  .app-spacing-x {
    @apply px-5 sm:px-6 md:px-8 2xl:px-0;
  }

  .app-spacing-l {
    @apply pl-5 sm:pl-6 md:pl-8 2xl:pl-0;
  }

  .app-spacing-r {
    @apply pr-5 sm:pr-6 md:pr-8 2xl:pr-0;
  }

  .container-xl {
    @apply max-w-7xl mx-auto;
  }

  .section-title {
    @apply text-2xl md:text-3xl lg:text-4xl xl:text-[44px] xl:leading-none font-semibold font-darker;
  }
}
